<template>
  <div>
    <About ref="about"/>
    <AssignCheckedStudies ref="assignCheckedStudies"/>
    <DownloadCheckedStudies ref="downloadCheckedStudies"/>
    <MoveCheckedStudies ref="moveCheckedStudies"/>
    <ImportStudy ref="importStudy"/>
    <PatientInfoEditor ref="patientInfoEditor"/>
    <ShareStudy ref="shareStudy"/>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="false"/>
    <b-modal ref="warningDialog" title="Worklist"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand class="d-none d-sm-block" href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-navbar-nav v-if="!isRefreshing">
        <b-badge class="ml-2" variant="secondary" show :size="buttonSize" :title="'Last Updated: '+refreshTime">{{ worklistCount }} <span class="d-none d-sm-inline">Exams</span></b-badge>
        <b-badge v-if="!refreshFlag" class="ml-1" variant="warning" title="Latest Refresh Failed"><b-icon icon="exclamation-triangle"></b-icon></b-badge>
      </b-navbar-nav>
      <b-navbar-nav v-if="isRefreshing">
        <b-spinner class="ml-2" label="Loading..." variant="info"></b-spinner>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-badge v-if="worklistCount>=queryLimit" class="ml-2 d-none d-sm-block" variant="warning" title="Worklist may be truncated, use a more specific filter."><b-icon icon="exclamation-triangle"></b-icon></b-badge>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-badge class="ml-2" :title="'Logged in as: '+user" variant="secondary"><b-icon icon="person"></b-icon><span class="d-none d-sm-inline"> {{user}}</span></b-badge>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button class="ml-1" v-if="canViewImages" variant="secondary" title="View Selected Studies In New Windows" @click="viewStudies()" :disabled="zeroStudiesChecked">
          <b-icon icon="display"></b-icon>
        </b-button>
        <b-button class="d-none d-sm-inline" v-if="canMoveStudies" variant="secondary" title="Move Selected Studies to a Different Group/Tenant" @click="moveStudies()" :disabled="canMoveStudiesDisabled">
          <b-iconstack>
            <b-icon stacked icon="plus-circle" shift-v="5" shift-h="-6"></b-icon>
            <b-icon stacked icon="building" shift-v="-3" shift-h="4"></b-icon>
          </b-iconstack>
        </b-button>
        <b-button class="ml-1" variant="secondary" title="Edit Patient Demographics for Single Selected Study" @click="editPatientInfo()" :disabled="!canEditPatientInfo || !oneStudyChecked">
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
        <b-button class="ml-1" variant="secondary" title="Share Single Selected Study" @click="shareStudy()" :disabled="!canShareStudy || !oneStudyChecked">
          <span class="material-icons md-18">&#xe9c5;</span>
        </b-button>
        <b-button class="ml-1 d-none d-sm-block" variant="secondary" title="Download Selected Studies" @click="downloadStudies()" :disabled="!canDownloadStudies || zeroStudiesChecked">
          <b-icon icon="download"></b-icon>
        </b-button>
        <b-button class="ml-1 d-none d-sm-block" variant="secondary" title="Assign Selected Studies" @click="assignStudies()" :disabled="!canAssignStudies || zeroStudiesChecked">
          <b-icon icon="person-check"></b-icon>
        </b-button>
        <b-dropdown class="ml-1" title="Mark/Unmark Studies as Emergency" :size="buttonSize" right :disabled="!canMarkStat || zeroStudiesChecked">
          <template #button-content>
            <b-icon icon="exclamation-circle"></b-icon>
          </template>
          <b-dropdown-item @click="markStudies(true)"><b-icon icon="toggle-on"></b-icon> Mark as Emergency</b-dropdown-item>
          <b-dropdown-item @click="markStudies(false)"><b-icon icon="toggle-off"></b-icon> Mark as Normal</b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="ml-1" title="Functions" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="tools"></b-icon>
          </template>
          <b-dropdown-item v-if="isAdmin" @click="openAdministrativeConsole"><b-icon icon="terminal"></b-icon> Administrative Console</b-dropdown-item>
          <b-dropdown-item v-if="isAdmin" @click="openKeycloakConsole"><b-icon icon="key"></b-icon> Keycloak Console</b-dropdown-item>
          <b-dropdown-item @click="openLocalStudy"><b-icon icon="file-medical"></b-icon> Open Local DICOM Study</b-dropdown-item>
          <b-dropdown-item v-if="canUploadStudy" @click="importStudy"><b-icon icon="cloud-arrow-up"></b-icon> Import Local Study</b-dropdown-item>
          <b-dropdown-item v-if="canAssignStudies" @click="assignStudies" :disabled="zeroStudiesChecked">
            <b-icon icon="person-check"></b-icon> Assign Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canDownloadStudies" @click="downloadStudies" :disabled="zeroStudiesChecked">
            <b-icon icon="download"></b-icon> Download Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canMoveStudies" @click="moveStudies" :disabled="canMoveStudiesDisabled">
            <b-iconstack>
              <b-icon stacked icon="plus-circle" shift-v="5" shift-h="-8"></b-icon>
              <b-icon stacked icon="building" shift-v="-3" shift-h="2"></b-icon>
            </b-iconstack> Move Selected Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="canDoQuerySearch" @click="mgmtReports"><b-icon icon="table"></b-icon> Management Reports</b-dropdown-item>
          <b-dropdown-item v-if="canUseTemplateEditor" @click="editReportTeplates"><b-icon icon="journal-code"></b-icon> Edit Report Templates</b-dropdown-item>
          <b-dropdown-item @click="resetColumnWidths"><b-icon icon="arrow-left-right"></b-icon> Reset Column Widths</b-dropdown-item>
          <b-dropdown-item @click="about"><b-icon icon="info-circle"></b-icon> About</b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="ml-1" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{user}}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item>
          <b-dropdown-item @click="userHistory"><b-icon icon="card-list"></b-icon> History</b-dropdown-item>
          <b-dropdown-item @click="profile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
          <b-dropdown-item @click="logout"><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>  
</template>
<script>
import uuid from 'uuid'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import About from './About.vue'
import AssignCheckedStudies from './AssignCheckedStudies.vue'
import DownloadCheckedStudies from './DownloadCheckedStudies.vue'
import ImportStudy from './ImportStudy.vue'
import PatientInfoEditor from './PatientInfoEditor.vue'
import MoveCheckedStudies from './MoveCheckedStudies.vue'
import ShareStudy from './ShareStudy.vue'
import UserSettings from './UserSettings.vue'

export default {
  components: {
    About,
    AssignCheckedStudies,
    DownloadCheckedStudies,
    ImportStudy,
    MoveCheckedStudies,
    PatientInfoEditor,
    UserSettings,
    ShareStudy
  },
  data() {
    return {
      buttonSize: "sm",
      warningMsg: ""
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    canAssignStudies() {
      return (permissions.groupsForPermission(permissions.CAN_ASSIGN_STUDIES).length > 0)
    },
    canDoQuerySearch() {
      return (permissions.groupsForPermission(permissions.CAN_DO_QUERY_SEARCH).length > 0)
    },
    canEditPatientInfo() {
      return (permissions.groupsForPermission(permissions.CAN_EDIT_PI).length > 0)
    },
    canEditReports() {
      return (permissions.groupsForPermission(permissions.CAN_EDIT_REPORTS).length > 0)
    },
    canUseTemplateEditor() {
      return (permissions.groupsForPermission(permissions.CAN_USE_TEMPLATE_EDITOR).length > 0)
    },
    canDownloadStudies() {
      return (permissions.groupsForPermission(permissions.CAN_DOWNLOAD_STUDY).length > 0)
    },
    canMarkStat() {
      return (permissions.groupsForPermission(permissions.CAN_MARK_STAT).length > 0)
    },
    canShareStudy() {
      return (permissions.groupsForPermission(permissions.CAN_SHARE_STUDY).length > 0)
    },
    canViewImages() {
      return (permissions.groupsForPermission(permissions.CAN_VIEW_IMAGES).length > 0)
    },
    canUploadStudy() {
      return (permissions.groupsForPermission(permissions.CAN_UPLOAD_STUDY).length > 0)
    },
    canMoveStudies() {
      return false // +TODO+ (permissions.groupsForPermission(permission.CAN_MOVE_STUDY).length > 0)
    },
    canMoveStudiesDisabled() {
      return (this.$store.state.checkedStudies.length == 0)
    },
    exportObjs() {
      let eo = "Images"
      if (this.$store.state.exportStudy && this.$store.state.exportStudy.include_reports) {
        eo += "/Reports"
      }
      return eo
    },
    oneStudyChecked() {
      return (this.$store.state.checkedStudies.length == 1)
    },
    zeroStudiesChecked() {
      return (this.$store.state.checkedStudies.length == 0)
    },
    worklistCount() {
      return this.$store.state.worklistCount
    },
    queryLimit() {
      return this.$store.state.queryLimit
    },
    isAdmin() {
      return permissions.isAdmin()
    },
    isRefreshing() {
      return this.$store.state.worklistRefreshing
    },
    refreshFlag() {
      return this.$store.state.worklistRefreshFlag
    },
    refreshTime() {
      return this.$store.state.worklistRefreshTime
    },
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    }
  },
  methods: {
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    assignStudies() {
      this.$refs.assignCheckedStudies.show(permissions.groupsForPermission(permissions.CAN_ASSIGN_STUDIES))
    },
    downloadStudies() {
      this.$refs.downloadCheckedStudies.show(permissions.groupsForPermission(permissions.CAN_DOWNLOAD_STUDY))
    },
    editPatientInfo() {
      if (this.oneStudyChecked) {
        const checkedStudy = this.$store.state.checkedStudies[0]
        const studyUid = checkedStudy.slice(checkedStudy.lastIndexOf('|') + 1)
        if (studyUid != '') {
          this.$store.commit("changeSelectedStudyUids", studyUid)
          this.$refs.patientInfoEditor.show()
        }
      }
      return true
    },
    editReportTeplates() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'ReportTemplateEditor')
      }
    },
    markStudies(stat) {
      const state = stat ? 'stat' : 'normal'
      for (var i=0; i < this.$store.state.checkedStudies.length; i++) {
        const checkedStudy = this.$store.state.checkedStudies[i]
        const studyUid = checkedStudy.slice(checkedStudy.lastIndexOf('|') + 1)
        if (studyUid != '') {
          const entry = this.$store.getters.worklistEntryForStudy(studyUid)
          if (!stat && (entry.order_priority == 'STAT')) {
            const toastMsg = "Cannot change priorty, ordered as STAT [" + webServices.getTitleForEntry(entry) + "]"
            this.displayToast(toastMsg, 'warning')
            continue
          }
          if (entry.stat != stat) {
            webServices.updateStudyStat(entry, state)
            .then(response => {
              this.$log.debug(response)
              if (response.result == 'OK') {
                entry.stat = response.stat
                entry._rowVariant = webServices.reportStatusToVariant(entry.report_status, entry.read, entry.stat)
                let toastMsg = "[" + webServices.getTitleForEntry(entry) + "] marked "
                toastMsg += (entry.stat) ? " EMERGENCY PRIORITY" : " NORMAL PRIORITY"
                this.displayToast(toastMsg, 'success')
              }
              else {
                const toastMsg = response.result + " [" + webServices.getTitleForEntry(entry) + "]"
                this.displayToast(toastMsg, 'warning')
              }
            })
            .catch(err => {
              this.$log.error("Error updating stat setting for study: "+err)
            })
          }
        }
      }
      return true
    },
    mgmtReports() {
      this.$router.replace('mgmt_reports')
    },
    moveStudies() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', '')
        this.$refs.moveCheckedStudies.show(permissions.groupsForPermission(permissions.CAN_MOVE_STUDY))
      }
    },
    shareStudy() {
      if (this.oneStudyChecked) {
        const checkedStudy =  this.$store.state.checkedStudies[0]
        const studyUid = checkedStudy.slice(checkedStudy.lastIndexOf('|') + 1)
        if (studyUid != '') {
          this.$refs.shareStudy.show(studyUid)
        }
      }
      return true
    },
    viewStudies() {
      // Open selected studies that user can view in new windows.
      //
      var checkedStudies = []
      const allCheckedStudies = this.$store.state.checkedStudies
      const groupsForViewStudy = permissions.groupsForPermission(permissions.CAN_VIEW_IMAGES)
      for (var g=0; g<groupsForViewStudy.length; g++) {
        var groupPrefix = groupsForViewStudy[g]+'|'
        for (let j=0; j<allCheckedStudies.length; j++) {
          if (allCheckedStudies[j].startsWith(groupPrefix)) {
            const studyUid = allCheckedStudies[j].replace(groupPrefix, '')
            var worklistEntry = this.$store.getters.worklistEntryForStudy(studyUid)
            if ((worklistEntry != null) && !worklistEntry.oo) {
              checkedStudies.push(studyUid)
            }
          }
        }
      }

      const viewerWindowsMax = this.$configs.maxViewerWindows || 10
      var viewerWindowsN = Object.keys(this.$store.state.viewerWindowsRoute).length
      if (viewerWindowsMax < viewerWindowsN + checkedStudies.length) {
        this.warningMsg += `You have reached the allowed limit for viewer windows (maximum=${viewerWindowsMax}).<br>`
      }
      this.$log.debug(`1 viewerWindowsN=${viewerWindowsN}`)

      var s = 0
      while ((viewerWindowsN < viewerWindowsMax) && (s < checkedStudies.length)) {
        const studyUid = checkedStudies[s]
        const viewerWindowUid = uuid.v4()
        const viewerRoute = 'viewer?uid=' + encodeURIComponent(viewerWindowUid)
        let payload = {
          'studyUid': studyUid,
          'windowUid': viewerWindowUid
        }
        this.$store.commit("changeSelectedStudyUids", payload)
        this.$store.commit('changeViewerWindowsRoute', {
          'route': viewerRoute,
          'windowUid': viewerWindowUid
        })

        var box = this.$store.state.viewerWindowBox
        const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
        let viewerWindowName = 'saincepacs_viewer_'+viewerWindowUid
        let viewerWindow = window.open(`/#/${viewerRoute}`, viewerWindowName, windowOpts)
        this.$store.commit('changeViewerWindows', {
          'window': viewerWindow,
          'windowUid': viewerWindowUid
        })

        s++
        viewerWindowsN++
      }
      this.$log.debug(`2 viewerWindowsN=${viewerWindowsN}`)
      
      if (checkedStudies.length < allCheckedStudies.length) {
        this.warningMsg += "Unable to view one or more selected studies, missing view images permission for associated group/tenant and/or only the order is available.";
      }

      if (this.warningMsg != '') {
          this.$refs.warningDialog.show()
      }
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
    openAdministrativeConsole() {
      // Open in a new window/tab.
      // +TODO+ Replace adminUrl logic to not depend on web services location and hard-coded port.
      //
      var adminUrl = this.$store.state.webServicesBaseUrl.replace("/web_services", ":8443/dcm4chee-arc/ui2")
      window.open(adminUrl, "pacsadmin_console");
    },
    openKeycloakConsole() {
      // Open in a new window/tab.
      // +TODO+ Replace adminUrl logic to not depend on web services location and hard-coded port.
      //
      var adminUrl = this.$store.state.webServicesBaseUrl.replace("/web_services", "/auth/admin/dcm4che/console")
      window.open(adminUrl, "keycloak_console");
    },
    importStudy() {
      this.$refs.importStudy.show()
    },
    openLocalStudy() {
      this.$router.replace('viewer_local')
    },
    about() {
      this.$refs.about.show()
    },
    logout() {
      this.$router.replace('logout')
    },
    userHistory() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'UserHistory')
      }
    },
    userSettings() {
      this.$refs.userSettingsDialog.show()
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    },
    handleWarningOK() {
      this.warningMsg = ''
    },
    resetColumnWidths() {
      this.$emit('resetColumnWidths')
    }
  }
}    
</script>
<style scoped>
.md-18 {
  font-size: 18px;
}
</style>