<template>
  <div id="mainDiv" ref="mainDiv" class="p-1">
    <b-modal ref="wDialogRef" title="Worklist Filter"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only>
      <span>{{wDialogMsg}}</span>
    </b-modal>
    <WorklistSettings ref="worklistSettingsDialog"/>
    <b-modal ref="confirmDeleteWorklistDialog" title="Delete Worklist Filter"
      header-bg-variant="info" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="deleteWorklist">
      <span>Are you sure you want to delete the {{worklistName}} filter?</span>
    </b-modal>
    <b-collapse visible id="collapse-filter" class="border border-info" @hidden="shownOrHidden" @shown="shownOrHidden">
      <!-- X-SMALL SCREENS (mobile) -->
      <div v-if="divSize=='xs'" class="text-light p-1">
        <b-input-group size="sm" prepend="Patient Name:">
          <b-form-input size="sm" v-model="worklistFilter.patientName" placeholder="Leave empty for any" @change="dirty=true">
            {{worklistFilter.patientName}}
          </b-form-input>
        </b-input-group>
        <b-input-group size="sm" prepend="MRN:">
          <b-form-input size="sm" v-model="worklistFilter.patientId" placeholder="Leave empty for any" @change="dirty=true">
            {{worklistFilter.patientId}}
          </b-form-input>
        </b-input-group>
        <div class="d-flex">
          <b-dropdown size="sm" variant="light"  class="flex-grow-1">
            <template #button-content>
              <span class="prepend">Modalities:</span> <span class="ellipsis">{{ selectedModalities }}</span>
            </template>
            <b-dropdown-form>
              <b-checkbox v-model="anyModality" @change="anyModalityChanged">Any</b-checkbox>
              <b-form-checkbox-group stacked v-model="worklistFilter.modalities">
                <b-form-checkbox v-for="m in modalities" :key="m" :value="m" @change="oneModalityChanged">{{m}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <b-input-group size="sm" prepend="Acc Num:" >
          <b-form-input size="sm" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" @change="dirty=true">
            {{worklistFilter.accessionNumber}}
          </b-form-input>
        </b-input-group>
        <div class="d-flex">
          <b-dropdown size="sm" variant="light" class="flex-grow-1">
            <template slot="button-content">
              <span class="prepend">Report:</span> <span class="ellipsis mr-auto">{{ selectedReportStatus }}</span>
            </template>
            <b-dropdown-form>
              <b-checkbox v-model="anyReportStatus" @change="anyReportStatusChanged">Any</b-checkbox>
              <b-form-checkbox-group stacked v-model="worklistFilter.reportStatus">
                <b-form-checkbox v-for="r in reportStatus" :key="r.value" :value="r.value" @change="oneReportStatusChanged">{{r.display}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <div class="d-flex">
          <b-dropdown size="sm" variant="light"  class="flex-grow-1">
            <template slot="button-content">
              <span class="prepend">Diagnostic:</span> <span class="ellipsis mr-auto">{{ selectedDiagnostic }}</span>
            </template>
            <b-dropdown-form>
              <b-checkbox v-model="anyDiagnostic" @change="anyDiagnosticChanged">Any</b-checkbox>
              <b-form-checkbox-group stacked v-model="worklistFilter.diagnostic">
                <b-form-checkbox v-for="d in diagnostic" :key="d" :value="d" @change="oneDiagnosticChanged">{{d}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <b-input-group size="sm" prepend="Reported By:">
          <b-form-input size="sm" v-model="worklistFilter.reportedBy" placeholder="Leave empty for any" @change="dirty=true">
            {{worklistFilter.reportedBy}}
          </b-form-input>
        </b-input-group>
        <div class="text-light border border-secondary">
          <b-input-group size="sm" prepend="Study Dates:">
            <b-form-select v-model="studyDateFilter" :options="studyDateOptions" @change="handleStudyDateFilter">
            </b-form-select>
          </b-input-group>
          <div class="d-flex w-100">
            <b-input-group size="sm" prepend="from:">
              <b-form-datepicker v-model="worklistFilter.studyDateFrom"
                :max="worklistFilter.studyDateTo"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :locale="locale"
                @input="usingCustomDate">
                <template #button-content></template>
              </b-form-datepicker>
            </b-input-group>
            <b-input-group size="sm" prepend="to:">
              <b-form-datepicker v-model="worklistFilter.studyDateTo"
                offset="-80"
                :min="worklistFilter.studyDateFrom"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :locale="locale"
                @input="usingCustomDate">
                <template #button-content></template>
              </b-form-datepicker>
            </b-input-group>
          </div>
        </div>
      </div>
      <!-- SMALL SCREENS -->
      <div v-if="divSize=='sm'" class="text-light p-1">
        <b-form>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Patient Name:">
                <b-form-input size="sm" v-model="worklistFilter.patientName" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientName}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="MRN:">
                <b-form-input size="sm" v-model="worklistFilter.patientId" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientId}}
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Acc Num:" >
                <b-form-input size="sm" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.accessionNumber}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Reported By:">
                <b-form-input size="sm" v-model="worklistFilter.reportedBy" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.reportedBy}}
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Modalities:</span> <span class="ellipsis">{{ selectedModalities }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyModality" @change="anyModalityChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.modalities">
                    <b-form-checkbox v-for="m in modalities" :key="m" :value="m" @change="oneModalityChanged">{{m}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template slot="button-content">
                  <span class="prepend">Report:</span> <span class="ellipsis mr-auto">{{ selectedReportStatus }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyReportStatus" @change="anyReportStatusChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.reportStatus">
                    <b-form-checkbox v-for="r in reportStatus" :key="r.value" :value="r.value" @change="oneReportStatusChanged">{{r.display}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col cols="4">
              <b-dropdown right size="sm" variant="light" block>
                <template slot="button-content">
                  <span class="prepend">Diagnostic: </span><span class="ellipsis">{{ selectedDiagnostic }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyDiagnostic" @change="anyDiagnosticChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.diagnostic">
                    <b-form-checkbox v-for="d in diagnostic" :key="d" :value="d" @change="oneDiagnosticChanged">{{d}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Study Dates:">
                <b-form-select v-model="studyDateFilter" :options="studyDateOptions" @change="handleStudyDateFilter">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="from:">
                <b-form-datepicker v-model="worklistFilter.studyDateFrom"
                  :max="worklistFilter.studyDateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="to:">
                <b-form-datepicker v-model="worklistFilter.studyDateTo"
                  offset="-80"
                  :min="worklistFilter.studyDateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
      <!-- MEDIUM SCREENS -->
      <div v-if="divSize=='md'" class="text-light p-1">
        <b-form>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Patient Name:">
                <b-form-input size="sm" v-model="worklistFilter.patientName" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientName}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="MRN:">
                <b-form-input size="sm" v-model="worklistFilter.patientId" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientId}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Acc Num:" >
                <b-form-input size="sm" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.accessionNumber}}
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col cols="4">
              <b-input-group size="sm" prepend="Reported By:">
                <b-form-input size="sm" v-model="worklistFilter.reportedBy" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.reportedBy}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Modalities:</span> <span class="ellipsis">{{ selectedModalities }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyModality" @change="anyModalityChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.modalities">
                    <b-form-checkbox v-for="m in modalities" :key="m" :value="m" @change="oneModalityChanged">{{m}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Report:</span> <span class="ellipsis">{{ selectedReportStatus }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyReportStatus" @change="anyReportStatusChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.reportStatus">
                    <b-form-checkbox v-for="r in reportStatus" :key="r.value" :value="r.value" @change="oneReportStatusChanged">{{r.display}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col cols="4">
              <b-dropdown right size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Diagnostic:</span> <span class="ellipsis">{{ selectedDiagnostic }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyDiagnostic" @change="anyDiagnosticChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.diagnostic">
                    <b-form-checkbox v-for="d in diagnostic" :key="d" :value="d" @change="oneDiagnosticChanged">{{d}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Study Dates:">
                <b-form-select v-model="studyDateFilter" :options="studyDateOptions" @change="handleStudyDateFilter">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="from:">
                <b-form-datepicker v-model="worklistFilter.studyDateFrom"
                  :max="worklistFilter.studyDateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="to:">
                <b-form-datepicker v-model="worklistFilter.studyDateTo"
                  offset="-80"
                  :min="worklistFilter.studyDateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
      <!-- LARGE SCREENS -->
      <div v-if="divSize=='lg'" class="text-light p-1">
        <b-form>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Patient Name:">
                <b-form-input size="sm" v-model="worklistFilter.patientName" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientName}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="MRN:">
                <b-form-input size="sm" v-model="worklistFilter.patientId" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientId}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Acc Num:" >
                <b-form-input size="sm" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.accessionNumber}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Reported By:">
                <b-form-input size="sm" v-model="worklistFilter.reportedBy" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.reportedBy}}
                </b-form-input>
              </b-input-group>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Modalities:</span> <span class="ellipsis">{{ selectedModalities }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyModality" @change="anyModalityChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.modalities">
                    <b-form-checkbox v-for="m in modalities" :key="m" :value="m" @change="oneModalityChanged">{{m}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col cols="4">
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Report:</span> <span class="ellipsis">{{ selectedReportStatus }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyReportStatus" @change="anyReportStatusChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.reportStatus">
                    <b-form-checkbox v-for="r in reportStatus" :key="r.value" :value="r.value" @change="oneReportStatusChanged">{{r.display}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col cols="4">
              <b-dropdown right size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Diagnostic:</span> <span class="ellipsis">{{ selectedDiagnostic }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyDiagnostic" @change="anyDiagnosticChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.diagnostic">
                    <b-form-checkbox v-for="d in diagnostic" :key="d" :value="d" @change="oneDiagnosticChanged">{{d}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Study Dates:">
                <b-form-select v-model="studyDateFilter" :options="studyDateOptions" @change="handleStudyDateFilter">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="from:">
                <b-form-datepicker v-model="worklistFilter.studyDateFrom"
                  :max="worklistFilter.studyDateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="to:">
                <b-form-datepicker v-model="worklistFilter.studyDateTo"
                  offset="-80"
                  :min="worklistFilter.studyDateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
      <!-- X-LARGE SCREENS -->
      <div v-if="divSize=='xl'" class="text-light p-1">
        <b-form>
          <b-form-row class="mt-1">
            <b-col>
              <b-input-group size="sm" prepend="Patient Name:">
                <b-form-input size="sm" v-model="worklistFilter.patientName" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientName}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="MRN:">
                <b-form-input size="sm" v-model="worklistFilter.patientId" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.patientId}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Acc Num:" >
                <b-form-input size="sm" v-model="worklistFilter.accessionNumber" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.accessionNumber}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Reported By:">
                <b-form-input size="sm" v-model="worklistFilter.reportedBy" placeholder="Leave empty for any" @change="dirty=true">
                  {{worklistFilter.reportedBy}}
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Modalities:</span> <span class="ellipsis">{{ selectedModalities }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyModality" @change="anyModalityChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.modalities">
                    <b-form-checkbox v-for="m in modalities" :key="m" :value="m" @change="oneModalityChanged">{{m}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-col>
              <b-dropdown size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Report:</span> <span class="ellipsis">{{ selectedReportStatus }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyReportStatus" @change="anyReportStatusChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.reportStatus">
                    <b-form-checkbox v-for="r in reportStatus" :key="r.value" :value="r.value" @change="oneReportStatusChanged">{{r.display}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col>
              <b-dropdown right size="sm" variant="light" block>
                <template #button-content>
                  <span class="prepend">Diagnostic:</span> <span class="ellipsis">{{ selectedDiagnostic }}</span>
                </template>
                <b-dropdown-form>
                  <b-checkbox v-model="anyDiagnostic" @change="anyDiagnosticChanged">Any</b-checkbox>
                  <b-form-checkbox-group stacked v-model="worklistFilter.diagnostic">
                    <b-form-checkbox v-for="d in diagnostic" :key="d" :value="d" @change="oneDiagnosticChanged">{{d}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="Study Dates:">
                <b-form-select v-model="studyDateFilter" :options="studyDateOptions" @change="handleStudyDateFilter">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="from:">
                <b-form-datepicker v-model="worklistFilter.studyDateFrom"
                  :max="worklistFilter.studyDateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group size="sm" prepend="to:">
                <b-form-datepicker v-model="worklistFilter.studyDateTo"
                  offset="-80"
                  :min="worklistFilter.studyDateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :locale="locale"
                  @input="usingCustomDate">
                  <template #button-content></template>
                </b-form-datepicker>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
    </b-collapse>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-button variant="secondary" @click="showFilter = !showFilter" v-b-toggle.collapse-filter size="sm">
          {{showFilter ? 'Hide Filter' : 'Show Filter'}}
        </b-button>
        <b-button class="ml-2" variant="secondary" :title="refreshTitle" @click="refreshWorklist" size="sm">
          <b-icon icon="arrow-clockwise"></b-icon> 
        </b-button>
      </b-navbar-nav>
      <b-badge v-if="divSize!='xs'" class="ml-2 d-none d-sm-block" variant="secondary" show size="sm">Use * for wildcard match.</b-badge>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-dropdown v-if="usePredefinedWorklists" class="ml-2" variant="secondary" title="" size="sm">
          <template #button-content>
            <b-icon icon="bookmark"/>  <span class="d-none d-sm-inline">Pre-Defined</span>
          </template>
          <b-dropdown-item v-for="w in worklistNames" v-bind:key="w" @click="changeWorklist">
            <b-icon v-if="w==defaultWorklistName" icon="bookmark-star"/>
            <b-icon v-else icon="bookmark"/> 
            <span v-if="w==worklistName"><strong> {{ w }}</strong></span>
            <span v-if="w!=worklistName"> {{ w }}</span>
          </b-dropdown-item>
          <template v-if="canEditWorklists">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="addWorklist"><b-icon icon="bookmark-plus"></b-icon> Add New</b-dropdown-item>
            <b-dropdown-item @click="editWorklist"><b-icon icon="pencil"></b-icon> Edit {{worklistName}}</b-dropdown-item>
            <b-dropdown-item :disabled="deleteWorklistDisabled" @click="confirmDeleteWorklist"><b-icon icon="trash"></b-icon> Delete {{worklistName}}</b-dropdown-item>
          </template>
        </b-dropdown>
        <b-button class="ml-2" variant="secondary" @click="clearFilter" size="sm">Clear Filter</b-button>
        <b-button class="ml-2" variant="info" @click="applyFilter" :disabled="!dirty" size="sm">Apply Filter</b-button>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import broadcast from '../common/broadcast'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import WorklistFilter from '../common/worklistFilter'
import WorklistSettings from './WorklistSettings.vue'

export default {
  name: 'worklistAdHocFilter',
  components: {
    WorklistSettings
  },
  data() {
    return {
      anyDiagnostic: true,
      anyModality: true,
      anyReportStatus: true,
      diagnostic: ['Canceled', 'Final', 'In Transit', 'Read', 'Scheduled', 'Unread', 'Unverified'],
      dirty: false,
      divSize: 'md',
      modalities: ['CR', 'CT', 'DR', 'DX', 'ES', 'MG', 'MR', 'NM', 'OT', 'PT', 'RF', 'RG', 'SC', 'SR', 'US', 'VL', 'XA', 'XC'],
      origWorklistName: '',
      reportStatus: [
        {
          value: '---',
          display: 'No Report'
        },
        {
          value: 'DRAFT',
          display: 'Draft'
        },
        {
          value: 'PRELIMINARY',
          display: 'Preliminary'
        },
        {
          value: 'FINAL',
          display: 'Final'
        }
      ],
      showFilter: true,
      studyDateFilter: 'today',
      studyDateOptions: [
        { value: "today", text: "Today" },
        { value: "yesterday", text: "Yesterday" },
        { value: "threeDays", text: "Past 3 days" },
        { value: "week", text: "Past 7 days" },
        { value: "month", text: "Past 30 days" },
        { value: "60d", text: "Past 60 days" },
        { value: "90d", text: "Past 90 days" },
        { value: "6m", text: "Past 6 months" },
        { value: "year", text: "Past year" },
        { value: "custom", text: "Custom" }
      ],
      wDialogMsg: '',
      worklistFilter: new WorklistFilter(this.$store.state.adHocWorklistName),
    };
  },
  mounted() {
    // Watch for changes to filter width via mainDiv size
    //
    const ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.$log.debug(`width=${entry.contentRect.width}`)
        this.handleResize(entry.contentRect.width)
      }
    });
    const mainDivElem = document.getElementById('mainDiv')
    ro.observe(mainDivElem);

    this.handleResize(this.$refs['mainDiv'].clientWidth)

    // Only observe the main div
    ro.observe(document.getElementById('mainDiv'));

    webServices.readSystemSetting("modalities")
    .then(response => {
      if ((response != null) && Array.isArray(response) && (response.length > 0)) {
        for (var i=0; i<response.length; i++)
        {
          if (!this.modalities.includes(response[i])) {
            this.modalities.push(response[i])
          }
        }
        this.modalities.sort()
      }
    })
    .catch(err => {
      this.$log.error("Error fetching cached settings: "+err)
    })

    // Reload existing filter.
    //
    this.worklistFilter = new WorklistFilter(this.$store.state.adHocWorklistName)
    const adHocFilter = this.$store.getters.worklistFilter(this.$store.state.adHocWorklistName)
    this.worklistFilter.copy(adHocFilter)
    this.$log.debug(this.worklistFilter)
    this.initFilter()
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    canEditWorklists() {
      return (permissions.groupsForPermission(permissions.CAN_EDIT_WORKLISTS).length > 0)
    },
    usePredefinedWorklists() {
      return this.$configs.usePredefinedWorklists
    },
    defaultWorklistName() {
      return this.$store.getters.defaultWorklistName
    },
    deleteWorklistDisabled() {
      return (this.worklistName == this.$store.getters.defaultWorklistName)
    },
    refreshTime() {
      return this.$store.state.worklistRefreshTime
    },
    refreshTitle() {
      return `Refresh Worklist (Last Updated: ${this.refreshTime})`
    },
    selectedDiagnostic() {
      var str = "Any"
      var n = this.worklistFilter.diagnostic.length
      if ((n > 0) && (n < this.diagnostic.length)) {
        str = this.worklistFilter.diagnostic.slice().sort().join(",")
      }
      return str 
    },
    selectedModalities() {
      var str = "Any"
      var n = this.worklistFilter.modalities.length
      if ((n > 0) && (n < this.modalities.length)) {
        str = this.worklistFilter.modalities.slice().sort().join(",")
      }
      return str
    },
    selectedReportStatus() {
      var str = "Any"
      var n = this.worklistFilter.reportStatus.length
      if ((n > 0) && (n < this.reportStatus.length)) {
        var reportStatus = []
        for (var i=0; i<n; i++) {
          for (var j=0; j<this.reportStatus.length; j++) {
            if (this.reportStatus[j].value == this.worklistFilter.reportStatus[i]) {
              reportStatus.push(this.reportStatus[j].display)
              break
            }
          }
        }
        str = reportStatus.slice().sort().join(",")
      }
      return str 
    },
    worklistNames() {
      return this.$store.state.worklistNames
    },
    worklistName() {
      return this.$store.state.worklistName
    },
  },
  watch:{
    worklistName(/*newVal, oldVal*/) {
      this.$store.commit('changeNextStudyUid', null)
      this.$store.commit('changePreviousStudyUid', null)
      let payload = {
        nextStudyUid: null,
        previousStudyUid: null,
        window_uid: this.$store.state.uid
      }
      broadcast.postMessage(broadcast.WORKLIST_NEXT_PREV_MSG, payload)
    }
  },
  methods: {
    applyFilter() {
      // +TODO+
      //
      this.$log.debug(`Updating cache for worklistSettings`)
      const worklistSettingsBackup = JSON.stringify(this.$store.state.worklistSettings)
      let updatedWorklistFilter = new WorklistFilter(this.$store.state.adHocWorklistName) // need to cache a copy, not a reference
      updatedWorklistFilter.copy(this.worklistFilter)
      this.$store.commit('changeWorklistFilter', updatedWorklistFilter)
      webServices.updateUserSetting("worklistSettings", this.$store.state.worklistSettings)
      .then(() => {
        this.$log.debug('Refeshing worklist')
        this.$store.commit('changeWorklistName', updatedWorklistFilter.worklistName)
        this.refreshWorklist()
        this.$emit('filterChanged', updatedWorklistFilter.worklistName)
        this.dirty = false
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
        webServices.updateUserSetting("worklistSettings", JSON.parse(worklistSettingsBackup))
        this.wDialogMsg = 'Unable to save new settings.'
        this.$refs['wDialogRef'].show()
      })
    },
    clearFilter() {
      this.worklistFilter = new WorklistFilter(this.$store.state.adHocWorklistName)
      this.initFilter()
      this.applyFilter()
    },
    initFilter() {
      this.anyDiagnostic = (this.worklistFilter.diagnostic.length == 0)
      this.anyModality = (this.worklistFilter.modalities.length == 0)
      this.anyReportStatus = (this.worklistFilter.reportStatus.length == 0)
      this.studyDateFilter = this.worklistFilter.studyDateFilter 
    },
    anyDiagnosticChanged(checked) {
      this.dirty = true
      if (checked) {
        this.worklistFilter.diagnostic = []
      }
    },
    oneDiagnosticChanged(checked) {
      this.dirty = true
      if (checked) {
        this.anyDiagnostic = false
      }
    },
    anyModalityChanged(checked) {
      this.dirty = true
      if (checked) {
        this.worklistFilter.modalities = []
      }
    },
    oneModalityChanged(checked) {
      this.dirty = true
      if (checked) {
        this.anyModality = false
      }
    },
    anyReportStatusChanged(checked) {
      this.dirty = true
      if (checked) {
        this.worklistFilter.reportStatus = []
      }
    },
    oneReportStatusChanged(checked) {
      this.dirty = true
      if (checked) {
        this.anyReportStatus = false
      }
    },
    initializeStudyDates() {
      // Support values not handled by back-end (those will be converted to 'custom')
      //
      const today = new Date()
      let d = new Date()
      this.worklistFilter.studyDateFilter = this.studyDateFilter
      switch(this.studyDateFilter) {
        case 'today':
          this.worklistFilter.studyDateFrom = today.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case 'yesterday':
          d.setDate(today.getDate() - 1)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = d.toISOString().split('T')[0]
          break
        case 'threeDays':
          d.setDate(today.getDate() - 3)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case 'week':
          d.setDate(today.getDate() - 7)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case 'month':
          d.setDate(today.getDate() - 30)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case '60d':
          d.setDate(today.getDate() - 60)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case '90d':
          d.setDate(today.getDate() - 90)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case '6m':
          d.setMonth(today.getMonth() - 6)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        case 'year':
          d.setFullYear(today.getFullYear() - 1)
          this.worklistFilter.studyDateFrom = d.toISOString().split('T')[0]
          this.worklistFilter.studyDateTo = today.toISOString().split('T')[0]
          break
        default:
          break
      }
    },
    shownOrHidden() {
      this.$emit('filterDisplay', this.showFilter)
    },
    handleStudyDateFilter() {
      this.dirty = true
      this.initializeStudyDates()
    },
    usingCustomDate() {
      this.dirty = true
      this.studyDateFilter = 'custom'
      this.worklistFilter.studyDateFilter = this.studyDateFilter
    },
    addWorklist() {
      this.$refs.worklistSettingsDialog.show('', true)
    },
    changeWorklist() {
      try {
        this.$store.commit('changeActiveComponent', "")
        this.$store.commit("changeSelectedStudyUids", "")
        this.$store.commit('changeWorklist', [])
        this.$store.commit('changeWorklistName', event.currentTarget.innerText.substring(1))
        this.refreshWorklist()
      }
      catch(err) {
        this.$log.error('Failed to refresh worklist: '+err);
      }
    },
    confirmDeleteWorklist() {
      this.$refs['confirmDeleteWorklistDialog'].show()
    },
    deleteWorklist() {
      this.$store.commit("removeWorklistFilter", this.$store.state.worklistName)
      var worklistName = this.$store.getters.defaultWorklistName
      this.$store.commit("changeWorklistName", worklistName)

      this.$log.debug("Updating cache for worklistSettings")
      webServices.updateUserSetting("worklistSettings", this.$store.state.worklistSettings)
      .then(response => {
        this.$log.debug(response)
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
    },
    editWorklist() {
      this.$refs.worklistSettingsDialog.show(this.$store.state.worklistName, true)
    },
    refreshWorklist() {
      this.$store.commit("changeSelectedStudyUids", "")
      this.$log.debug("---> WorklistAdHocFilter CALLING webServices.readWorklist()")
      webServices.readWorklist()
    },
    handleResize(width) {
      // Handle breakpoints if not using entire window width (e.g., in split).
      //
      var size = "xs"
      if (width >= 1200) {
        size = "xl"
      }
      else if (width >= 992) {
        size = "lg"
      }
      else if (width >= 768) {
        size = "md"
      }
      else if (width >= 576) {
        size = "sm"
      }
      this.$log.debug(`divSize=[${size}]`)
      this.divSize = size
      this.$nextTick(() => {
        this.$emit('filterDisplay', this.showFilter)
      })
    }
  }
};
</script>
<style scoped>
.cbM {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.md-18 {
  font-size: 18px;
}
.prepend {
  float: left;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>